'use strict';

// Imports.
import { ethersService } from './index';
import { ethers } from 'ethers';
import axios from 'axios';
import config from '/src/config'
import { log } from '/src/utility';

const getItemMetadata = async function (address, id) {
    let provider = await ethersService.getProvider();

    let itemContract = new ethers.Contract(
        address,
        config.birdswapABI,
        provider
      );

    let metadata = await itemContract.tokenURI(id);
	let parsedMeta;

	if(metadata && metadata !== ""){
		const trimmedMeta = metadata.substring(29);
		const decodedMetadata = Buffer.from(trimmedMeta, 'base64');
		const stringMetadata = decodedMetadata.toString('ascii');

		parsedMeta = JSON.parse(stringMetadata);
		const imageData = parsedMeta.image.substring(26);
		const decodedImage = Buffer.from(imageData, 'base64');
		const imageString = decodedImage.toString('ascii');
	}

    return {
        "name": await itemContract.name(),
        "symbol": await itemContract.symbol(),
		"id": id,
        "image": parsedMeta ? parsedMeta.image : "",
        "attributes": parsedMeta ? parsedMeta.attributes : [],
        "description": parsedMeta ? parsedMeta.description : ""
    }
}

const getBirdswapContract = async function(provider){
    let network = await provider.getNetwork();
    let networkId = ethers.utils.hexValue(network.chainId);
    let birdswapAddress = config.birdswap[networkId];
    let birdswapContract = new ethers.Contract(
        birdswapAddress,
        config.birdswapABI,
        provider
    );
	
	return birdswapContract;
}

const checkInteractedState = async function() {
	let provider = await ethersService.getProvider();
    let signer = await provider.getSigner();
    let walletAddress = await signer.getAddress();

	let birdswapContract = await getBirdswapContract(provider);
	let interacted = await birdswapContract.interacted(walletAddress);
	return interacted;
}

const purchaseItem = async function( dispatch ){
	let provider = await ethersService.getProvider();
    let signer = await provider.getSigner();
    let walletAddress = await signer.getAddress();

    let birdswapContract = await getBirdswapContract(provider);
    
    //let totalSpend = pool.price.mul(amount);

    let purchaseTx = await birdswapContract.connect(signer).mint(walletAddress);

    await dispatch(
        'alert/info',
        {
            message: 'Transaction Submitted',
            metadata: {
            transaction: purchaseTx.hash
            },
            duration: 300000
        },
        { root: true }
    );
    await purchaseTx.wait();

    await dispatch('alert/clear', '', { root: true });
        await dispatch(
        'alert/info',
        {
            message: 'Transaction Confirmed',
            duration: 10000
        },
        { root: true }
    );
};

const swapItem = async function( id, dispatch ){
	let provider = await ethersService.getProvider();
    let birdswapContract = await getBirdswapContract(provider);
    
	// get victim address
	let victimAddress = await birdswapContract.ownerOf(id);
	console.log("victimAddress", victimAddress);
    let swapTx = await birdswapContract.connect(signer).swap(victimAddress, id);

    await dispatch(
        'alert/info',
        {
            message: 'Transaction Submitted',
            metadata: {
            transaction: swapTx.hash
            },
            duration: 300000
        },
        { root: true }
    );
    await swapTx.wait();

    await dispatch('alert/clear', '', { root: true });
        await dispatch(
        'alert/info',
        {
            message: 'Transaction Confirmed',
            duration: 10000
        },
        { root: true }
    );
};

const getOwnedItems = async function( ) {
    let provider = await ethersService.getProvider();
    let signer = await provider.getSigner();
    let walletAddress = await signer.getAddress();

	let birdswapContract = await getBirdswapContract(provider);

    let ownershipData = {};

    let filterToWallet = birdswapContract.filters.Transfer(null, walletAddress);
    let filterFromWallet = birdswapContract.filters.Transfer(walletAddress, null);

    let singleTransfers = [
        ...await safeQueryFilter(birdswapContract, filterToWallet),
        ...await safeQueryFilter(birdswapContract, filterFromWallet)
    ].sort((a,b) => {
        let block = a.blockNumber - b.blockNumber;
        if(block !== 0){
            return block;
        }
        return a.transactionIndex - b.transactionIndex;
    });

    for(const t of singleTransfers){
        ownershipData[t.args.tokenId.toNumber()] = {
            tokenId: t.args.tokenId.toNumber(),
            collectionAddress: t.address,
            owner:  t.args.to
        }
    }

    let ownedItems = Object.values(ownershipData).filter((transfer) => transfer.owner.toLowerCase() === walletAddress.toLowerCase());

    for (let item of ownedItems) {
        let metadata = await getItemMetadata(item.collectionAddress, item.tokenId);
        item.metadata = metadata;
    }
    return ownedItems;
};

const safeQueryFilter = async function (contract, event, startBlock = 0, endBlock) {
    let start = startBlock;
    const end = endBlock ?? await contract.provider.getBlockNumber();
    let endRange = end;

    let results = [];
    do {
        if (start >= endRange) {
            endRange = end;
        }
        let singleTransfers = [];
        try {
            singleTransfers = await contract.queryFilter(event, start, endRange);
        } catch (e) {
            const mid = Math.round((start + endRange) / 2);
            endRange = mid;
            continue;
        }
        results = results.concat(singleTransfers);
        start = endRange + 1;
    } while (endRange < end);
    return results;
};

const getMintedItems = async function() {
	let provider = await ethersService.getProvider();
    let signer = await provider.getSigner();
    let walletAddress = await signer.getAddress();
    let birdswapContract = await getBirdswapContract(provider);

	let ownershipData = {};

    let filterToWallet = birdswapContract.filters.Transfer(null, null);

    let singleTransfers = [
        ...await safeQueryFilter(birdswapContract, filterToWallet),
    ].sort((a,b) => {
        let block = a.blockNumber - b.blockNumber;
        if(block !== 0){
            return block;
        }
        return a.transactionIndex - b.transactionIndex;
    });

	console.log("singleTransfers", singleTransfers);

    for(const t of singleTransfers){
        ownershipData[t.args.tokenId.toNumber()] = {
            tokenId: t.args.tokenId.toNumber(),
            collectionAddress: t.address,
            owner:  t.args.to
        }
    }

    let notOwnedItems = Object.values(ownershipData).filter((transfer) => transfer.owner.toLowerCase() !== walletAddress.toLowerCase());

    for (let item of notOwnedItems) {
        let metadata = await getItemMetadata(item.collectionAddress, item.tokenId);
        item.metadata = metadata;
    }
    return notOwnedItems;
};

// Export the user service functions.
export const birdswapService = {
	checkInteractedState,
	getItemMetadata,
    getOwnedItems,
    purchaseItem,
	swapItem,

	getMintedItems
  };