'use strict';

// Import our module information.
import actions from './actions';
import mutations from './mutations';

// Prepare state.
const state = {
  item: {},
  mintedItems: [],
  ownedItems: [],
  interacted: false
};

// Return the module prepared for use.
export default {
  namespaced: true,
  state,
  actions,
  mutations
};
