<template>
  <div class="mintSkeletonContainer">
    <Skeletor class="skeleton header"></Skeletor>
    <Skeletor class="skeleton subtitle"></Skeletor>
  </div>
</template>
<script>
import { computed } from 'vue';

import 'vue-skeletor/dist/vue-skeletor.css';
import { Skeletor } from 'vue-skeletor';

export default {
  components: {
    Skeletor
  },

  props: {},

  setup(props) {}
};
</script>
<style scoped lang="scss">
.mintSkeletonContainer {
  width: 400px;
  height: 400px;
  max-width: 80vw;
  display: flex;
  align-items: center;
  flex-direction: column;

  .skeleton {
    border-radius: 0;
    width: 100%;
    
    background-color: rgba($color: white, $alpha: 0.2);
  }

  .header {
    height: 100%;
    width: 100%;
    
  }

  .subtitle {
    height: 80px;
    width: 100%;
    margin: 20px 0;
  }

  .image {
    height: 250px;
    width: 250px;
    margin-bottom: 30px;
  }

  .remaining {
    height: 40px;
    width: 50%;
  }

  .dropdown {
    height: 50px;
    width: 60%;
  }
}
</style>
