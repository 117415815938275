<template>
  <Ethereum :callback="initialize" />
  <div v-if="!isConnected" style="margin-top:100px;">
    <ConnectWallet />
  </div>
  <div v-else-if="isLoading" class="skeleton">
    <div class="main">
      <div class="saleGrid">
        <div class="saleItem" v-for="(number, index) in 10" :key="index">
          <MintSkeleton />
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="!isLoading && isConnected" class="main">
      <div class="saleGrid">
          <div 
              
              class="saleItem"
              >
              <!-- <div class="itemCell">
                <img :src="item.metadata.image" class="smallThumb" />
              </div> -->
              
              <div class="itemInfo">
				<!-- <img :src="item.metadata.image" class="smallThumb" /> -->
              </div>
              <div class="itemCell">
				<h3>bird</h3>
				<div class="price">0.0 ETH</div>
				<div > 69 remaining</div>
				<br><br>
				<Button v-if="!interacted"  @click="purchaseItem()">Mint</Button>
				<Button v-if="interacted" class="deactivated">Mint</Button>
				
              </div>
          </div>
      </div>
  </div>
  
</template>
<script>
// Imports.

import { computed, ref, onMounted, watch } from 'vue';
import { useStore } from 'vuex';

// Component imports.
import Button from '/src/components/ui/Button.vue';
import Ethereum from '/src/components/common/Ethereum.vue';
import ConnectWallet from '/src/components/common/ConnectWallet.vue';
import MintSkeleton from './components/MintSkeleton.vue';

export default {
  components: {
    Button,
	ConnectWallet,
    Ethereum,
    MintSkeleton,
},

  setup() {
    const store = useStore();

    const isConnected = computed(() => {
      return store.state.ethers.canSign;
    });

    const isLoading = ref(true);

	const interacted = computed(() => {
		return store.state.birdswap.interacted;
	});

    const initialize = async () => {
    //   await store.dispatch(
    //     'birdswap/getMintedItems',
    //     { },
    //     { root: true }
    //   );
		await store.dispatch(
			'birdswap/checkInteractedState',
			{ },
			{ root: true }
		);
		isLoading.value = false;
		console.log(store.state.composite.mintableItems);
    };

    const purchaseItem = async () => {
		console.log("MINT");
		await store.dispatch(
			'birdswap/purchaseItem',
			{},
			{ root: true }
		);
    };

    return {
		interacted,
		isConnected,
		isLoading,
		initialize,
		purchaseItem,
    };
  }
};
</script>
<style scoped lang="scss">

.skeleton {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.main {
    width:80%;
    margin:40px auto;
  }
  .smallThumb {
    width:100px;
    height:100px;
  }  
  .medThumb {
    width:250px;
    height:250px;
  }
  .saleItem {
    margin:10px 40px;
    padding:10px;
    display:flex;
    flex-direction:row;
    background:rgb(0,0,0);
  }
  .itemCell {
    width:50%;
    padding:10px 5px;
  }
  .itemInfo {
    width:50%;
  }
  
  .modalCell {
    width:300px;
    margin:auto;
  }
  .attributeList {
    width:720px;
    margin:auto;
    flex-direction:row;
  }
  .saleGrid {
    //display:flex;
    flex-direction:row;
    flex-wrap: wrap;
  }
  .mintModal {
    background:rgb(0,0,0);
    margin:200px auto;
    width: 720px;
    padding:20px;
    z-index:111;
    border:2px solid red;

  }
  .mintModalInfo {
    display:flex;
    flex-direction:row;
  }
  .mintBlackout {
    background:rgba(0,0,0,.8);
    position:fixed;
    top:0px;
    width:100%;
    height:100%;

  }
  .closeButton {
    float:right;
    cursor:pointer;
  }

  .buttonWidth {
    width:200px;
  }

  .attrWrap {
    width:680px;
    border:1px solid rgb(0,0,100);
    margin:5px 0;
  }
  .attrType {
    display:inline-block;
    width:50%;
    padding:5px;
  }
  
  .attrValue {
    display:inline-block;
    width:50%;
    padding:5px;
  }

  .deactivated {
	background: #777;
	color: #aaa;
	cursor: not-allowed
  }
</style>
