<template>
  <Ethereum :callback="initialize" />
  <div v-if="!isConnected" style="margin-top:100px;">
    <ConnectWallet />
  </div>
  <div v-else-if="isLoading" class="skeleton">
    <div class="main">
      <div class="saleGrid">
        <div class="saleItem" v-for="(number, index) in 4" :key="index">
          <MintSkeleton />
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="!isLoading && isConnected" class="main">
      <div class="saleGrid">
          <div 
              v-for="(item, index) in swappableItems" 
              :key="index"
              class="saleItem"
              >
              <div class="itemCell">
                <img :src="item.metadata.image" class="lgThumb" />
              </div>
              
			  <div v-if="!interacted" class="itemInfo" style="float:right">
				<Button @click="swapItem(item.metadata.id)">Swap</Button>
			  </div>
			  <div v-if="interacted" class="itemInfo" style="float:right">
				<Button class="deactivated">Swap</Button>
			  </div>
              <div class="itemInfo">
                  <h3>{{item.metadata.name}}</h3>
                  <h3># {{item.metadata.id}}</h3>
				  
              </div>
              
          </div>
      </div>
  </div>
</template>
<script>
// Imports.
import { computed, ref, onMounted, watch } from 'vue';
import { useStore } from 'vuex';

// // Component imports.
import Button from '/src/components/ui/Button.vue';
import Ethereum from '/src/components/common/Ethereum.vue';
import ConnectWallet from '/src/components/common/ConnectWallet.vue';
import MintSkeleton from '../mint/components/MintSkeleton.vue';

export default {
  components: {
    Button,
    Ethereum,
    ConnectWallet,
    MintSkeleton
},

  setup() {
    const store = useStore();

    const isConnected = computed(() => {
      return store.state.ethers.canSign;
    });

    const isLoading = ref(true);

    const swappableItems = computed(() => {
      return store.state.birdswap.mintedItems;
    });

	const interacted = computed(() => {
      return store.state.birdswap.interacted;
    });

    const initialize = async () => {
		if(store.state.ethers.canSign){
			await store.dispatch(
				'birdswap/getMintedItems',
				{ },
				{ root: true }
			);
			await store.dispatch(
				'birdswap/checkInteractedState',
				{ },
				{ root: true }
			);
		}
      
      isLoading.value = false;
      console.log(store.state.composite.mintableItems);
    };

    const swapItem = async (id) => {
      console.log("MINT", id);
      await store.dispatch(
        'birdswap/swapItem',
        { 
          id
        },
        { root: true }
      );

	  // refresh items 
	  await store.dispatch(
        'birdswap/getMintedItems',
        { },
        { root: true }
      );
    };

    return {
		interacted,
		isConnected,
		isLoading,
		swappableItems,
		initialize,
		swapItem,
    };
  }
};
</script>
<style scoped lang="scss">

.skeleton {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.main {
    width:80%;
    margin:40px auto;
  }
  .smallThumb {
    width:100px;
    height:100px;
  }  
  .medThumb {
    width:250px;
    height:250px;
  }
  .lgThumb {
    width:400px;
    height:400px;
  }
  .saleItem {
    margin:10px 40px;
    padding:10px;
    background:rgb(0,0,0);
  }
  .itemCell {
    padding:10px 5px;
  }

  
  .modalCell {
    width:300px;
    margin:auto;
  }
  .attributeList {
    width:720px;
    margin:auto;
    flex-direction:row;
  }
  .saleGrid {
    display:flex;
    flex-direction:row;
    flex-wrap: wrap;
  }
  .mintModal {
    background:rgb(0,0,0);
    margin:200px auto;
    width: 720px;
    padding:20px;
    z-index:111;
    border:2px solid red;

  }
  .mintModalInfo {
    display:flex;
    flex-direction:row;
  }
  .mintBlackout {
    background:rgba(0,0,0,.8);
    position:fixed;
    top:0px;
    width:100%;
    height:100%;

  }
  .closeButton {
    float:right;
    cursor:pointer;
  }

  .buttonWidth {
    width:200px;
  }

  .attrWrap {
    width:680px;
    border:1px solid rgb(0,0,100);
    margin:5px 0;
  }
  .attrType {
    display:inline-block;
    width:50%;
    padding:5px;
  }
  
  .attrValue {
    display:inline-block;
    width:50%;
    padding:5px;
  }

  .deactivated {
	background: #777;
	color: #aaa;
	cursor: not-allowed
  }
</style>
