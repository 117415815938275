'use strict';

// Imports.
import { createStore } from 'vuex';

// Import data modules for the store.
import alert from './alert';
import birdswap from './birdswap';
import ethers from './ethers';
import login from './login';

// Export the data store using our modules.
export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    alert,
	birdswap,
    ethers,
    login
  }
});
