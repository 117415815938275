'use strict';

// Specified state transitions per particular actions.
export default {
	updateInteractedState(state, interactedState) {
        state.interacted = interactedState;
    },

    updateMintedItems(state, mintedItems) {
        state.mintedItems = mintedItems;
    },

	updateOwnedItems(state, ownedItems) {
        state.ownedItems = ownedItems;
    },
};
