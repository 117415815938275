<template>
  <Ethereum :callback="initialize" />
  <div v-if="!isConnected" style="margin-top:100px;">
	<ConnectWallet />
  </div>
  <div v-else class="home">
	<router-link class="link" v-smooth-scroll to="/mint">Mint</router-link>
      | 
	<router-link class="link" v-smooth-scroll to="/swap">Swap</router-link>
  </div>
</template>
<script>
import Ethereum from '/src/components/common/Ethereum.vue';
import ConnectWallet from '/src/components/common/ConnectWallet.vue';

import { computed, ref, onMounted, watch } from 'vue';
import { useStore } from 'vuex';

export default {
  components: {
    Ethereum,
	ConnectWallet
  },

  setup() {
    const store = useStore();
    const isConnected = computed(() => {
      return store.state.ethers.canSign;
    });

    const initialize = async () => {

	};

    return {
      store,
      isConnected,
      initialize
    };
  }
};
</script>
<style scoped lang="scss">
.home {
  text-align: center;
  padding: 150px;
}
</style>
