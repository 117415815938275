import birdswapABI from './ABIs/birdswap.json';

const config = {
  infuraProjectId: '954e2f5992304238b4382298b5e9dea2',
  alchemyApiKey: {
    '0x4': '3UFuUZqwIV6g7mTvIZl30GYIFSkXqh_z',
    '0x5': 'SmRibxWnqII5gXgiuPDRiJT4gzA1vo7t'
  },

  networkPollingIntervals: {
    '0x1': 12000,
    '0x3': 12000,
    '0x4': 12000,
    '0x5': 12000,
    '0x2a': 12000
  },

  birdswap: {
	'0x1': '0xb69841a8a8e32cebb6ae1ae2fbf5c6ef2d8e354c',
	'0x5': '0x4b7dd6f903ee8604a51f766e498f00fc6d82759a'
  },

  birdswapABI,

  reservedMintCount: 444,
  mintPaddingPercent: 2,

  lang: {
    projectName: 'Project Name',
    projectNameLegal: 'Project-Legal-Name',
    whitelistDescriptions: [
      'Whitelist #0 description',
      'Whitelist #1 description',
      'Whitelist #2 description',
    ]
  },

  discordUrl: 'https://discord.gg/some_discord',
  twitterUrl: 'https://twitter.com/some_twitter'
};

export default config;
