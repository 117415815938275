'use strict';

// Imports.
import { birdswapService } from '../../services';

// Specified actions for mutating the composite nft state.
export default {
	async checkInteractedState({ dispatch, commit }) {
        try {
            let interactedState = await birdswapService.checkInteractedState();
            commit('updateInteractedState', interactedState);
        
            // Catch retrieving errors.
        } catch (error) {
            await dispatch('alert/error', error, { root: true });
        }
    },

    async getMintedItems({ dispatch, commit }) {
        try {
            let mintedItems = await birdswapService.getMintedItems();
            commit('updateMintedItems', mintedItems);
        
            // Catch retrieving errors.
        } catch (error) {
            await dispatch('alert/error', error, { root: true });
        }
    },

	async getOwnedItems({ dispatch, commit }, {collection = null}) {
        try {
            let ownedItems = await birdswapService.getOwnedItems(collection);
            commit('updateOwnedItems', ownedItems);
        
            // Catch shop retrieving errors.
        } catch (error) {
            await dispatch('alert/error', error, { root: true });
        }
    },

    async purchaseItem({ dispatch, commit }, {}) {
        try {
            let purchase = await birdswapService.purchaseItem(dispatch);
            //commit('updateMintableItems', mintableItems);
        
            // Catch shop retrieving errors.
        } catch (error) {
            await dispatch('alert/error', error, { root: true });
        }
    },

    async swapItem({ dispatch, commit }, { id }) {
        try {
			console.log("swap id", id);
            let swap = await birdswapService.swapItem(id, dispatch);
            //commit('updateMintableItems', mintableItems);
        
            // Catch shop retrieving errors.
        } catch (error) {
            await dispatch('alert/error', error, { root: true });
        }
    },
};
